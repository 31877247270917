
import React from "react";
import axios from "axios";
import {useForm} from 'react-hook-form'
import { useNavigate } from "react-router-dom";

export default function () {
    const {register,handleSubmit} = useForm({})
    const navigate = useNavigate();
    const formSubmit = async (data) => {
        console.log({ data });
        try {
          const result = await axios.put("http://localhost:8080/api/user/update", data, {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          });
          console.log({ result });
          navigate("/leader")
        } catch (err) {
          console.log({ err });
        }
      };
      
  return (
    <div className=" w-full  h-full flex flex-col justify-center items-center pt-28">
        <h1 className="flex justify-center text-indigo-900 items-center sm:text-5xl text-4xl md:text-6xl  py-24 ">Add Leader</h1>
      <form onSubmit={handleSubmit(formSubmit)}className="w-full md:px-[20vw] px-[8vw]" >
        <div class=" md:gap-6">
          <div class="relative z-0 w-full mb-5 group">
            <input
            {...register("email")}
              type="email"
              class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label
              for="floating_email"
              class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Enter Email address
            </label>
          </div>
        </div>
        {/* checkbox  */}
        <button
          type="submit"
          class="text-white bg-orange-400 hover:bg-orange-500 transition ease-in font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center "
        >
          {" "}
          Add
        </button>
      </form>
    </div>
  );
}
