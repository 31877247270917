import React from "react";

export default function About() {
  return (
    <div className="pt-28">
      <div className="flex flex-col px-5 md:px-2 gap-2 justify-center items-center">
        <h1 className="md:text-5xl text-3xl font-semibold">
          राष्ट्रिय मुक्ति क्रान्ति नेपाल को अवधारणा पत्र
        </h1>
        <h1>(कार्यदिशाको संस्लेषण, कार्यनिति एवं एकीकृत कार्ययोजना)</h1>
      </div>
    </div>
  );
}
