import React,{useState,useEffect} from "react";
import LeaderPerson from "./LeaderPerson";
import axios from "axios";
export default function ListLeader() {
  const [userdetail,setuserDetail]=useState([])
  const userDetail = async()=>{
    const result = await axios.get("http://localhost:8080/api/user/get-admin", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    setuserDetail(result?.data?.user)

  };
  console.log({userdetail});
  
 
  
  useEffect(()=>{
    userDetail();
  },[])
  return (
    <>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left overflow-x-auto rtl:text-right text-gray-500">
  {/* Header Table */}
  <thead className="text-xs text-gray-700 overflow-x-auto uppercase bg-gray-50">
    <tr>
      <th scope="col" className="px-6 py-3">
        Leader Name
      </th>
      <th scope="col" className="px-6 py-3">
        Email
      </th>
      <th scope="col" className="px-6 py-3">
        Phone
      </th>
      <th scope="col" className="px-6 py-3">
        Sex
      </th>
      <th scope="col" className="px-6 py-3">
        Action
      </th>
    </tr>
  </thead>
  
  {/* Body Table */}
  <tbody className="z-0">
    {userdetail?.map((item, idx) => (
      <LeaderPerson
        key={idx} // Important to add a unique key
        name={item?.name}
        email={item?.email}
        sex={item?.sex} // Assuming the state is static for now
        phone={item?.phone_number}
      />
      
    ))}
  </tbody>
</table>

      </div>
    </>
  );
}
