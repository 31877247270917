import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ListLeader from "../smallComponent/ListLeader";
import NoLeader from "../smallComponent/NoLeader";
import axios from "axios";

export default function Leader() {
  const [userdetail, setuserDetail] = useState([]);
  const userDetail = async () => {
    const result = await axios.get("https://api.rastriyamuktikranti.com/api/user/get-admin", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    setuserDetail(result?.data?.user);
  };
  console.log({ userdetail });

  useEffect(() => {
    userDetail();
  }, []);
  return (
    <>
      {userdetail?.length > 0 ? (
        <>
          <div className="pt-28 h-full flex flex-col justify-between items-center ">
            <div className="flex justify-between gap-20 w-full px-2 sm:px-8 items-center">
              <h1 className="text-black font-medium  text-3xl md:text-5xl">
                List of Leaders
              </h1>
              <div>
                <Link to="/addleader">
                  <button className="px-3 rounded-lg py-1 shadow-md shadow-gray-500 hover:shadow-gray-500  hover:bg-emerald-600 hover:shadow-md flex justify-center items-center gap-1 bg-emerald-500 text-white">
                    <div className="sm:text-lg text-xs"> Add </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        class="stroke-slate-100  sm:h-6 sm:w-6 h-4 w-4 hover:rotate-90 fill-none  duration-300"
                      >
                        <path
                          d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                          stroke-width="1.5"
                        ></path>
                        <path d="M8 12H16" stroke-width="1.5"></path>
                        <path d="M12 16V8" stroke-width="1.5"></path>
                      </svg>
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </div>{" "}
          <div className="h-screen px-4 pt-10">
            <ListLeader />
          </div>
        </>
      ) : (
        <>
         
          <div className="flex justify-center w-full items-center pt-28">
            <NoLeader></NoLeader>
          </div>
        </>
      )}
    </>
  );
}
