import React from "react";
import { Link } from "react-router-dom";

export default function NoLeader() {
  return (
    <div className=" flex bg-slate-100 mt-20 flex-col justify-center items-center rounded-lg p-9 font-mono ">
      <h1 className="text-4xl md:text-5xl">
        {" "}
        <span className="flex justify-center items-center">
          <svg
            class="h-16 w-16 my-2 text-slate-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            {" "}
            <circle cx="12" cy="12" r="10" />{" "}
            <line x1="12" y1="16" x2="12" y2="12" />{" "}
            <line x1="12" y1="8" x2="12.01" y2="8" />
          </svg>
        </span>
        No Leader found{" "}
      </h1>
      <div className="py-9">
      <Link to="/addleader">
        <button className="px-3 rounded-lg py-1 hover:bg-emerald-600 hover:shadow-lg flex justify-center items-center gap-1 bg-emerald-500 text-white">
          <div className="text-xl"> Add </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="stroke-slate-100 h-6 w-6 hover:rotate-90 fill-none  duration-300"
            >
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke-width="1.5"
              ></path>
              <path d="M8 12H16" stroke-width="1.5"></path>
              <path d="M12 16V8" stroke-width="1.5"></path>
            </svg>
          </div>
        </button>
        </Link>
      </div>
    </div>
  );
}
