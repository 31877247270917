import React, { useState } from 'react';
import Join from "./Join";
import Join2 from "./Join2";
import Join3 from "./Join3";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const Add=()=>{
    const [active, setActive] = useState(1);
    const [formData, setFormData] = useState();
    const formDataImage = new FormData();
    const navigate = useNavigate();
    const { register,
        handleSubmit,setValue,reset} = useForm({})

    const submit = async(data)=>{
        console.log({data});
        
        if(active===1)
        {
            setActive(2)
        }
        if(active===2)
        {
            console.log({data});
            formDataImage.append('name',data?.name)
            formDataImage.append('email',data?.email)

           

            formDataImage.append('phone_number',data?.phone_number)

            formDataImage.append('citizen',data?.citizen)

            formDataImage.append('sex',data?.sex)

            formDataImage.append('caste',data?.caste)
            formDataImage.append('dob',data?.dob)
            formDataImage.append('address',data?.address)

            formDataImage.append('state',data?.state)

            formDataImage.append('city',data?.city)

            formDataImage.append('pincode',data?.pincode)
            formDataImage.append('password',data?.password)

            formDataImage.append('assembly',data?.assembly)
           const file = data?.profilepic[0]
           console.log({file});
           
            
            formDataImage.append('profilepic', file )
            for (let pair of formDataImage.entries()) {
                console.log({ pair });
              }
          
           await axios.post('https://api.rastriyamuktikranti.com/api/user/add',formDataImage).then(result => {
            console.log({result});
            navigate("/join3")
          
          })
          .catch(error => {
            console.log(error);
          });

            // reset();
            
        }
    }
    return(
        <>
        {active===1 &&(
            <Join
            register={register}
          handleSubmit={handleSubmit}
          formSubmit={submit}
          />
        )}
        {active === 2 && (
        <Join2
         
          register={register}
          handleSubmit={handleSubmit}
         
          formSubmit={submit}
          setValue={setValue}
         
        />
      )}
        </>
    );
};

export default Add;