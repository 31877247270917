import axios from "axios";
import React, { useState } from "react";
import { useRef } from "react";
import ConfirmModal from "./confirmModal";
import { useNavigate } from "react-router-dom";
export default function LeaderPerson(props) {
  console.log({props});

  // const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      const data = { email: props?.email };
      const result = await axios.put("http://localhost:8080/api/user/update-admin", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      console.log({ result });

      // Navigate after successful update
      window.location.reload();

    } catch (err) {
      console.log({ err });
    }
  };

  // Open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Confirm action
  const confirmAction = () => {
    handleDelete();
    closeModal();
  };

  return (
    <>
      <tr class="odd:bg-white  even:bg-gray-50  border-b">
        <th
          scope="row"
          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
        >
          {props.name}
        </th>
        <td id="email" class="px-6 py-4">
          {props.email}
        </td>
        <td id="phone" class="px-6 py-4">
          {props.phone}
        </td>
        <td id="state" class="px-6 py-4">
          {props.sex}
        </td>
        <td class="px-6 py-4">
        <button
        onClick={openModal}
        type="button"
        className="py-2 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 focus:outline-none disabled:opacity-50 disabled:pointer-events-none"
        aria-haspopup="dialog"
        aria-expanded="false"
        aria-controls="hs-vertically-centered-modal"
        data-hs-overlay="#hs-vertically-centered-modal"
      >
        Remove
      </button>

      {/* Render the Confirm Modal */}
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmAction}
      />

     
        </td>
      </tr>
    </>
  );
}
