import { create } from 'zustand';

export const useUserStore = create(set => ({
  user: null,
  isAuthenticated: false,
  isFetching: true,
//   usertype: null,
  accessToken: localStorage.getItem('token') || null,
  login: (userData, accessToken) => {
    set({
      user: userData,
      isAuthenticated: true,
      accessToken: accessToken,
    //   usertype: userData.usertype
    });
    localStorage.setItem('token', accessToken);
  },
//   setIsFetching: isFetching => {
//     set({ isFetching });
//   },
  logout: () => {
    set({
      user: null,
      accessToken: null,
    //   userRole: null,
      isAuthenticated: false
    });
    localStorage.removeItem('token');
  }
  //   setSideBarSchoolAdmin: () => {
  //     set(prev => ({
  //       isSideBarOpenForSchoolAdmin: !prev.isSideBarOpenForSchoolAdmin
  //     }));
  //   }
}));
