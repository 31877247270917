import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../hooks/useUserStore";
import axios from "axios";
export default function Login() {
  const {register,handleSubmit} =useForm({})
  const setLogin = useUserStore(state=>state.login);
  const navigate = useNavigate();
  const formSubmit = async(data)=>{
    console.log({data});
    await axios.post("https://api.rastriyamuktikranti.com/api/user/login",data

    ).then(result=>{
      console.log({result});
      
      setLogin(result.data.user,result.data.accessToken)
      navigate('/');
    }).catch(err=>{
      console.log({err});
      
    })
  } 
  return (
    <div class="py-[20vh] sm:py-[10vh]   bg-white flex justify-center rounded-xl ">
      <div class="p-4 sm:p-7 w-full max-w-[100vh] ">
        <div className="flex text-orange-400 py-10 justify-center font-bold items-center">
        
     
        </div>
        <div class="text-center pt-5">
          <h1 class="block text-2xl font-bold text-gray-800">Sign in</h1>
      
        </div>

        <div class="mt-5">
          {/* <div class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6">Or</div> */}

          {/* <!-- Form --> */}
          <form onSubmit={handleSubmit(formSubmit)}className="w-full flex">
            <div class="grid w-full  items-center gap-y-4">
              {/* <!-- Form Group --> */}
              <div className="w-full">
                <label for="email" class="block text-sm mb-2">
                  Email address
                </label>
                <div class="relative">
                  <input
                  {...register("email")}
                    type="email"
                    id="email"
                    name="email"
                    class="py-3 px-4 block w-full border-gray-300 rounded-lg text-sm  disabled:opacity-50 disabled:pointer-events-none"
                    required
                    aria-describedby="email-error"
                  />
                  <div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                    <svg
                      class="size-5 text-red-500"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      aria-hidden="true"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                  </div>
                </div>
                <p class="hidden text-xs text-red-600 mt-2" id="email-error">
                  Please include a valid email address so we can get back to you
                </p>
              </div>
              {/* <!-- End Form Group --> */}

              {/* <!-- Form Group --> */}
              <div>
                <div class="flex justify-between items-center">
                  <label for="password" class="block text-sm mb-2">
                    Password
                  </label>
                  <a
                    class="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline  font-medium"
                    href=""
                  >
                    Forgot password?
                  </a>
                </div>
                <div class="relative">
                  <input
                    {...register("password")}
                    type="password"
                    id="password"
                    name="password"
                    class="py-3 px-4 block w-full border-gray-300 rounded-lg text-sm  disabled:opacity-50 disabled:pointer-events-none"
                    required
                    aria-describedby="password-error"
                  />
                  <div class="hidden absolute inset-y-0 end-0 pointer-events-none pe-3">
                    <svg
                      class="size-5 text-red-500"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      aria-hidden="true"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                  </div>
                </div>
                <p class="hidden text-xs text-red-600 mt-2" id="password-error">
                  8+ characters required
                </p>
              </div>
              {/* <!-- End Form Group --> */}

              {/* <!-- Checkbox --> */}
              <div class="flex items-center">
                <div class="flex">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="shrink-0 mt-0.5 border-gray-200 rounded "
                  />
                </div>
                <div class="ms-3">
                  <label for="remember-me" class="text-sm">
                    Remember me
                  </label>
                </div>
              </div>
              {/* <!-- End Checkbox --> */}

              <button
                type="submit"
                class="w-full py-3 px-4 inline-flex justify-center transition ease-in items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-orange-400 text-white hover:bg-orange-400/80 focus:outline-none  disabled:opacity-50 disabled:pointer-events-none"
              >
                Sign in
              </button>
            </div>
          </form>
          {/* <!-- End Form --> */}
        </div>
      </div>
    </div>
  );
}
