import React from "react";
import img1 from "../assets/imagesgallery/img1.jpeg";
import img2 from "../assets/imagesgallery/img2.jpeg";
import img3 from "../assets/imagesgallery/img3.jpeg";
import img4 from "../assets/imagesgallery/img4.jpeg";
import img5 from "../assets/imagesgallery/img5.jpeg";
import img6 from "../assets/imagesgallery/img6.jpeg";
import img7 from "../assets/imagesgallery/img7.jpeg";
import img8 from "../assets/imagesgallery/img8.jpeg";
import img9 from "../assets/imagesgallery/img9.jpeg";
import img10 from "../assets/imagesgallery/img10.jpeg";
import img12 from "../assets/imagesgallery/img12.jpeg";
import img13 from "../assets/imagesgallery/img13.jpeg";
import img14 from "../assets/imagesgallery/img14.jpeg";
import img15 from "../assets/imagesgallery/img15.jpeg";
import img16 from "../assets/imagesgallery/img16.jpeg";
import img17 from "../assets/imagesgallery/img17.jpeg";
import img18 from "../assets/imagesgallery/img18.jpeg";
import img19 from "../assets/imagesgallery/img19.jpeg";
import img20 from "../assets/imagesgallery/img20.jpeg";
import img21 from "../assets/imagesgallery/img21.jpeg";
import img22 from "../assets/imagesgallery/img22.jpeg";
import img23 from "../assets/imagesgallery/img23.jpeg";
import img24 from "../assets/imagesgallery/img24.jpeg";
import img25 from "../assets/imagesgallery/img25.jpeg";
import img26 from "../assets/imagesgallery/img26.jpeg";
import img27 from "../assets/imagesgallery/img27.jpeg";
import img28 from "../assets/imagesgallery/img28.jpeg";
import SingleImage from "../smallComponent/SingleImage";

export default function Photos() {
  const pics = [
    { pic: img1 },
    { pic: img2 },
    { pic: img3 },
    { pic: img4 },
    { pic: img5 },
    { pic: img7 },
    { pic: img8 },
    { pic: img9 },
    { pic: img10 },
    { pic: img28 },
    { pic: img12 },
    { pic: img13 },
    { pic: img14 },
    { pic: img15 },
    { pic: img16 },
    { pic: img17 },
    { pic: img18 },
    { pic: img19 },
    { pic: img20 },
    // { pic: img21 },
    { pic: img22 },
    { pic: img23 },
    { pic: img24 },
    { pic: img25 },
    { pic: img26 },
    { pic: img27 },
  ];

  return (
    <div className="bg-gray-200 py-28">
      <div className="flex justify-center items-center  pb-10">
        <h1 className="text-green-600 text-3xl lg:text-5xl sm:text-4xl font-semibold">
          IMAGES <span className="text-blue-800">GALLERY</span>
        </h1>
      </div>
      <div class="md:px-20 px-8 grid grid-cols-1 sm:grid-cols-2 justify-center md:grid-cols-3 gap-8">
        {pics.map((elem) => {
          return <SingleImage pic={elem.pic} key={elem} />;
        })}
      </div>
    </div>
  );
}
